/* TradingBots.css */

/* Styling the main container */
.trading-bots-container {
  display: flex; /* Makes Sidebar and Main Content align side-by-side */
  height: 100vh; /* Full viewport height */
}

/* Styling the main content */
.main-content {
  flex-grow: 1; /* Ensures main content takes available space */
  padding: 20px; /* Space around the main content */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetics */
  margin-left: 250px; /* Adjust based on the sidebar width */
  border-radius: 8px; /* Optional, adds rounded corners */
}

.trading-bots-container {
  display: flex;
  height: calc(100vh - 60px); /* Adjust based on your header height */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.card {
  border: 1px solid #2c3e50;
}

.form-control, .btn {
  background-color: #2c3e50;
  border-color: #34495e;
  color: #ecf0f1;
}

.form-control:focus {
  background-color: #34495e;
  border-color: #3498db;
  color: #ecf0f1;
}

@media (max-width: 991px) {
  .trading-bots-container {
    flex-direction: column;
  }
}

