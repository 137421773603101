.right-tab-container {
  background-color: #1E2329;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  height: 60%;
  max-width: 600px;
  margin: 20px auto;

}

.nav-tabs {
  border-bottom: 1px solid #343a40;
}

.nav-tabs .nav-link {
  color: #6c757d;
  border: none;
}

.nav-tabs .nav-link.active {
  color: #f7a600;
  background-color: transparent;
  border-bottom: 2px solid #f7a600;
}

.list-group-item {
  background-color: transparent;
  border-color: #343a40;
  color: #ffffff;
}