/* ActiveBotsList.css */

.card-height {
  height: 650px !important;
  overflow-y: auto;
}

.bot-tabs,
.sub-tabs {
  display: flex;
  margin-bottom: 1rem;
}

.bot-tabs .btn {
  flex: 1;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  background-color: #2c3e50;
  border: none;
  color: #ffffff;
  position: relative;
}

.bot-tabs .btn:last-child {
  margin-right: 0;
}

.bot-tabs .btn.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f7a600;
}

.sub-tabs .btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  position: relative;
}

.sub-tabs .btn::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2c3e50;
}

.sub-tabs .btn.active::after {
  background-color: #f7a600;
}

.bot-tabs .btn:hover,
.sub-tabs .btn:hover {
  color: #f7a600;
}

.bot-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

@media (max-width: 768px) {
  .bot-list {
    grid-template-columns: 1fr;
  }
}