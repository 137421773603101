.custom-scrollbar {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.scroll-content {
  height: 100%;
  overflow-y: scroll;
  padding-right: 20px; /* Adjust based on the width of your scrollbar */
  box-sizing: content-box;
}

.scroll-track {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 8px; /* Adjust the width of the scrollbar */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.scroll-thumb {
  position: absolute;
  width: 100%;
  background-color:#34495e;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.scroll-thumb:hover {
  background-color:#282c34;
}

.scroll-content::-webkit-scrollbar {
  display: none;
}