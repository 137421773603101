.sidebar {
  width: 250px;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  left: 0;
  background-color: #1e2533;
  transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
  z-index: 1000;
  color: #fff;
  font-size: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  padding: 20px;
  background-color: #161d2b;
  border-bottom: 1px solid #2c3548;
}

.sidebar-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.sidebar-content {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
}

.sidebar-content li {
  border-bottom: 1px solid #2c3548;
}

.sidebar-content a, .sidebar-content button.dropdown-toggle {
  display: block;
  padding: 15px 20px;
  text-decoration: none;
  color: #fff;
  transition: background-color 0.2s;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.sidebar-content a:hover, .sidebar-content button.dropdown-toggle:hover {
  background-color: #2c3548;
}

.dropdown-menu {
  background-color: #161d2b;
  padding-left: 20px;
}

.dropdown-menu a {
  padding-left: 40px;
}

.sidebar-toggle {
  display: none;
  position: fixed;
  top: 70px;
  left: 10px;
  z-index: 1001;
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.sidebar-backdrop {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 767px) {
  .sidebar {
    transform: translateX(-100%);
    left: -250px;
  }

  .sidebar.open {
    transform: translateX(0);
    left: 0;
  }

  .sidebar-toggle {
    display: block;
  }

  .main-content {
    margin-left: 0 !important;
    transition: margin-left 0.3s ease-in-out;
  }

  .main-content.sidebar-open {
    margin-left: 0 !important;
  }
}


@media (min-width: 768px) {
  .main-content {
    margin-left: 250px;
    padding-top: 70px;
    transition: margin-left 0.3s ease-in-out;
  }

  .main-content.sidebar-closed {
    margin-left: 0;
  }
}



.sidebar-content li {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
}

.sidebar-content button {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem;
  transition: background-color 0.2s;
}

.sidebar-content button:hover {
  background-color: #4a5568;

}

.sidebar-content button.active {
  background-color: #4a5568;
  border-left: 3px solid #f7a600;
}

.main-content {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.card {
  background-color: #2d3748;
  border: none;
  margin-bottom: 1rem;
}

.card-title {
  color: #fff;
}

.btn-warning {
  background-color: #f6ad55;
  border-color: #f6ad55;
}

.btn-outline-warning {
  color: #f6ad55;
  border-color: #f6ad55;
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #a0aec0;
}

@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    left: -250px;
    top: 0;
    bottom: 0;
    z-index: 1000;
  }

  .sidebar.open {
    left: 0;
  }

  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .sidebar-toggle {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 1001;
    background: none;
    border: none;
    color: #f7a600;
    margin-top: 40px;
    margin-left: -20px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}