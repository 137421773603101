.welcome-text {
  color: #f7a600;
  font-size: 4em;
  font-weight: 400;
  margin-top: 15px;
}

.lead-text {
  font-size: 1.8em;
  font-weight: 350;
}

.custom-input {
  background: #1E2329 !important;
  color: #ffffff !important;
  padding: 15px 25px !important;
  border-radius: 8px !important;
  margin-top: 20px;
  width: 100% !important;
}

.custom-input::placeholder {
  color: #6c757d !important;
  opacity: 1;
}

.custom-button {
  background: #f7a600 !important;
  color: #ffffff !important;
  padding: 15px 25px !important;
  border-radius: 8px !important;
  margin-top: 20px;

}

@media (max-width: 1200px) {
  .welcome-text {
    font-size: 3em;
  }
  .lead-text {
    font-size: 1.5em;
  }
}

@media (max-width: 768px) {
  .welcome-text {
    font-size: 2.5em;
  }
  .lead-text {
    font-size: 1.2em;
  }
}

@media (max-width: 576px) {
  .welcome-text {
    font-size: 2em;
  }
  .lead-text {
    font-size: 1em;
  }
}