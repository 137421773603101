

.bot-card {
  background-color: #2c3e50;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
  color: #ecf0f1;
  box-shadow: 0 4px 6px rgba(51, 49, 43, 0.1);
}

.bot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #34495e;
  padding-bottom: 10px;
}

.bot-header h5, .bot-header h6 {
  margin: 0;
}

.bot-id {
  font-size: 1em;
  font-weight: 500;
  color: #bdc3c7;
}

.bot-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.grid-item {
  padding: 2px;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.roe {
  color: #ecf0f1;
}

.btn {
  width: 100%;
  justify-content: center;
}
#stpbtn {
 width:80%;
  margin: 10px auto;
}

@media (max-width: 768px) {
  .bot-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item{
    font-size:1.2em !important;
  }
}


@media (max-width: 480px) {
  .bot-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  #thy{
    font-size:1em !important;
  }

  .bot-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .bot-id {
    margin-top: 5px;
  }
}