.bot-form-card {
  max-width: 600px;
  margin: 0 auto;
}

.compact-form .form-label {
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
}

.compact-form .form-control {
  font-size: 1rem;
  padding: 0.25rem 0.7rem;
}

.compact-form .mb-2 {
  margin-bottom: 0.5rem !important;
}

.compact-form .mb-3 {
  margin-bottom: 0.75rem !important;
}

.compact-form .row {
  margin-bottom: 0.25rem;
}

.compact-form .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
}
.mb-3{
  color: #f7a600;
}
