.api-container {
  align-items: center;
  justify-content: space-between;

  padding: 3rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.api-info {
  display: flex;
  align-items: center;
}

.api-info h4 {
  margin-right: 1rem;
}

.api-actions {
  display: flex;
  align-items: center;
}

.api-actions button {
  margin-left: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header h1 {
  margin-right: 1rem;
}


.api-card .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.api-info {
  flex-grow: 1;
}

.api-actions {
  flex-shrink: 0;
}
